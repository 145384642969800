// ProfileEditForm.js
import React, { useState } from 'react';

function ProfileEditForm({ profileData, setProfileData, setEditMode, setUser }) {
  const [email, setEmail] = useState(profileData?.email || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSave = async () => {
    if (password && password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    // Update profile data on backend
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/update-profile.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const updatedData = await response.json();
        setProfileData((prevData) => ({ ...prevData, email: updatedData.email }));
        setUser((prevUser) => ({ ...prevUser, email: updatedData.email }));
        setEditMode(false);
        alert('Profile updated successfully.');
      } else {
        const errorData = await response.json();
        alert(errorData.message || 'Error updating profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile.');
    }
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  return (
    <div className="profile-edit-form">
      <label>Email:</label>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <label>New Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <label>Confirm New Password:</label>
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <button onClick={handleSave}>Save Changes</button>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  );
}

export default ProfileEditForm;
