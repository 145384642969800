// App.js
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, AuthContext } from './AuthContext';
import Header from './Header';
import BaggageEstimator from './BaggageEstimator';
import Disclaimer from './Disclaimer';
import LoginModal from './LoginModal';
import Changelog from './Changelog';
import ContactUs from './ContactUs';
import SubmitRequest from './SubmitRequest';
import PrivacyPolicy from './PrivacyPolicy';
import AccountPage from './AccountPage';
import ProfilePage from './ProfilePage';
import SettingsPage from './SettingsPage';
import PurchaseHistoryPage from './PurchaseHistoryPage';
// import AdminCenterPage from './AdminCenterPage'; // For future admin center
import AdOverlay from './AdOverlay';
import Styles from './styles.css';
import RequireAuth from './RequireAuth';
// import RequireAdmin from './RequireAdmin'; // For protecting admin routes in the future

function App() {
  return (
    <AuthProvider>
      <Router>
        <MainApp />
      </Router>
    </AuthProvider>
  );
}

function MainApp() {
  const { isAuthenticated } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(!isAuthenticated);

  useEffect(() => {
    setIsModalOpen(!isAuthenticated);
  }, [isAuthenticated]);

  return (
    <div className="App">
      {isModalOpen && <LoginModal onClose={() => setIsModalOpen(false)} />}
      <div className={isModalOpen ? 'blurred' : ''}>
        <Header />
        <Routes>
          <Route path="/" element={<BaggageEstimator />} />
          <Route path="/changelog" element={<Changelog />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/submit-request" element={<SubmitRequest />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Account Routes */}
          <Route
            path="/account/*"
            element={
              <RequireAuth>
                <AccountPage />
              </RequireAuth>
            }
          >
            <Route path="profile" element={<ProfilePage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="purchase-history" element={<PurchaseHistoryPage />} />
            {/* Future admin route */}
            {/* <Route
              path="admin-center"
              element={
                <RequireAdmin>
                  <AdminCenterPage />
                </RequireAdmin>
              }
            /> */}
            {/* Default route under /account */}
            <Route path="" element={<Navigate to="profile" />} />
          </Route>

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Disclaimer />
        <footer className="footer">
          <p>
            © Simpayloads.com. All Rights Reserved.
            <a href="/privacy-policy" className="privacy-link">
              Privacy Policy
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
