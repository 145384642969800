// Sidebar.js
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.scss'; // Using SCSS for advanced styling
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faCog,
  faHistory,
  faBars,
  faTimes,
  faSearch,
  faMoon,
  faSun,
  faUserShield,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';

function Sidebar() {
  const { user } = useContext(AuthContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [darkMode, setDarkMode] = useState(false); // For theme toggle

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleTheme = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode', !darkMode);
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <button className="collapse-button" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={isCollapsed ? faBars : faTimes} />
        </button>
        {!isCollapsed && (
          <div className="user-info">
            <span className="username">{user?.username}</span>
          </div>
        )}
      </div>
      {!isCollapsed && (
        <>
          <div className="search-bar">
            <FontAwesomeIcon icon={faSearch} />
            <input type="text" placeholder="Search..." />
          </div>
          <nav>
            <ul>
              <li>
                <NavLink to="profile" className={({ isActive }) => (isActive ? 'active' : '')}>
                  <FontAwesomeIcon icon={faUser} />
                  <span>Profile</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="settings" className={({ isActive }) => (isActive ? 'active' : '')}>
                  <FontAwesomeIcon icon={faCog} />
                  <span>Settings</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="settings" className={({ isActive }) => (isActive ? 'active' : '')}>
                  <FontAwesomeIcon icon={faCog} />
                  <span>Products</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="purchase-history" className={({ isActive }) => (isActive ? 'active' : '')}>
                  <FontAwesomeIcon icon={faHistory} />
                  <span>Purchase History</span>
                </NavLink>
              </li>
              {user?.isAdmin && (
                <li>
                  <NavLink to="admin-center" className={({ isActive }) => (isActive ? 'active' : '')}>
                    <FontAwesomeIcon icon={faUserShield} />
                    <span>Admin Center</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
          <div className="theme-toggle">
            <button onClick={toggleTheme}>
              <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
              <span>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Sidebar;
