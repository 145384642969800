// BaggageEstimator.js
import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { FaQuestionCircle } from 'react-icons/fa'; // Font Awesome Question Circle Icon

// Import react-tooltip styles
import 'react-tooltip/dist/react-tooltip.css';

// Constants
const HEAVY_BAG_WEIGHT = 51; // lbs
const NORMAL_BAG_WEIGHT = 32; // lbs
const AVG_ADULT_WEIGHT = 208; // lbs
const AVG_CHILD_WEIGHT = 92; // lbs

// Plane Data
const planeData = {
  '737-700': {
    BEW: 83000,
    emptyCGArm: -6.171,
    maxFWDWeight: 4200,
    maxAFTWeight: 7000,
    PMDGZFWCorrection: 1693,
    maxPassengers: 143,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.6);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  '737-800': {
    BEW: 91300,
    emptyCGArm: -6.171,
    maxFWDWeight: 7500,
    maxAFTWeight: 10300,
    PMDGZFWCorrection: 1693,
    maxPassengers: 189,
    calculateBins: (totalBags) => {
      const binC =
        totalBags <= 120
          ? totalBags
          : Math.floor(Math.random() * 21) + 100; // Random between 100 and 120
      const binD = totalBags - binC;
      return { binC, binD };
    },
  },
  '737-MAX8': {
    BEW: 99360,
    emptyCGArm: -6.171,
    maxFWDWeight: 7600,
    maxAFTWeight: 10500,
    PMDGZFWCorrection: 0,
    maxPassengers: 177, // Sum of all zone capacities
    passengerZones: {
      A: { capacity: 12, arm: 17.91 },
      B: { capacity: 30, arm: 6.10 },
      C: { capacity: 36, arm: -6.28 },
      D: { capacity: 28, arm: -11.00 },
      E: { capacity: 24, arm: -16.43 },
      F: { capacity: 23, arm: -22.48 },
      G: { capacity: 24, arm: -28.05 },
    },
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  '777-300ER': {
    BEW: 326000,
    emptyCGArm: -6.171,
    maxFWDWeight: 41206,
    maxAFTWeight: 35095,
    PMDGZFWCorrection: 0,
    maxPassengers: 370,
    calculateBins: (totalBags) => {
      const binC = Math.ceil(totalBags * 0.6);
      const binD = totalBags - binC;
      return { binC, binD };
    },
  },
  'A319': {
    BEW: 89411,
    emptyCGArm: -6.171,
    maxFWDWeight: 2880,
    maxAFTWeight: 6170,
    PMDGZFWCorrection: 0,
    maxPassengers: 150,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  'A320': {
    BEW: 94137,
    emptyCGArm: -6.171,
    maxFWDWeight: 6170,
    maxAFTWeight: 6170,
    PMDGZFWCorrection: 0,
    maxPassengers: 180,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
  'A321': {
    BEW: 105932,
    emptyCGArm: -6.171,
    maxFWDWeight: 12500,
    maxAFTWeight: 12988,
    PMDGZFWCorrection: 0,
    maxPassengers: 230,
    calculateBins: (totalBags) => {
      const binD = Math.ceil(totalBags * 0.7);
      const binC = totalBags - binD;
      return { binC, binD };
    },
  },
};

// Function to calculate total bags based on passenger count
const getTotalBags = (paxCountNum) => {
  if (paxCountNum <= 5) {
    return Math.floor(Math.random() * 6); // 0-5
  } else if (paxCountNum <= 10) {
    return Math.floor(Math.random() * 11); // 0-10
  } else if (paxCountNum <= 15) {
    return Math.floor(Math.random() * 11); // 0-10
  } else if (paxCountNum <= 30) {
    return Math.floor(Math.random() * 26); // 0-25
  } else if (paxCountNum <= 50) {
    return Math.floor(Math.random() * 41); // 0-40
  } else if (paxCountNum <= 75) {
    return Math.floor(Math.random() * 66) + 15; // 15-80
  } else if (paxCountNum <= 143) {
    return Math.floor(Math.random() * 91) + 50; // 50-140
  } else if (paxCountNum <= 189) {
    return Math.floor(Math.random() * 111) + 100; // 100-210
  } else {
    // For passenger counts above 189
    // Adjusted to allow up to 450 bags
    return Math.floor(Math.random() * 301) + 150; // 150-450 bags
  }
};

// Tooltip content for each result item
const tooltipContent = {
  adultPaxCount: "Copy and paste this into Simbrief's PAX section.",
  totalPayload: "Copy and paste this into Simbrief's Payload section.",
};

function BaggageEstimator() {
  const [planeType, setPlaneType] = useState('');
  const [passengerCount, setPassengerCount] = useState('');
  const [freightWeight, setFreightWeight] = useState('');
  const [results, setResults] = useState({});
  const [buttonText, setButtonText] = useState('Estimate Baggage');
  const [simBriefResults, setSimBriefResults] = useState({});
  const [resultsLoading, setResultsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [maxPassengerCount, setMaxPassengerCount] = useState(null);
  const [passengerDistribution, setPassengerDistribution] = useState(null);

  // Update maxPassengerCount when planeType changes
  useEffect(() => {
    if (planeType && planeData[planeType]) {
      setMaxPassengerCount(planeData[planeType].maxPassengers);
    } else {
      setMaxPassengerCount(null);
    }

    // Reset passenger count and distribution when plane type changes
    setPassengerCount('');
    setPassengerDistribution(null);
  }, [planeType]);

  const distributePassengersToZones = (totalPassengers, zones) => {
    // Calculate total capacity and total weighted capacity
    let totalCapacity = 0;
    let totalWeightedCapacity = 0;

    for (const zone of Object.values(zones)) {
      totalCapacity += zone.capacity;
      totalWeightedCapacity += zone.capacity / Math.abs(zone.arm);
    }

    // Calculate initial allocation based on weighted proportions
    const initialAllocation = {};
    let allocatedPassengers = 0;

    for (const [zoneName, zone] of Object.entries(zones)) {
      const weight = (zone.capacity / Math.abs(zone.arm)) / totalWeightedCapacity;
      const passengersInZone = Math.floor(totalPassengers * weight);

      // Ensure we don't exceed zone capacity
      const adjustedPassengers = Math.min(passengersInZone, zone.capacity);
      initialAllocation[zoneName] = adjustedPassengers;
      allocatedPassengers += adjustedPassengers;
    }

    // Distribute remaining passengers starting from center
    let remainingPassengers = totalPassengers - allocatedPassengers;

    // Sort zones by proximity to desired CG (arm closest to zero)
    const sortedZones = Object.entries(zones).sort(
      (a, b) => Math.abs(a[1].arm) - Math.abs(b[1].arm)
    );

    for (const [zoneName, zone] of sortedZones) {
      if (remainingPassengers === 0) break;

      const availableCapacity = zone.capacity - initialAllocation[zoneName];

      if (availableCapacity > 0) {
        const passengersToAdd = Math.min(availableCapacity, remainingPassengers);
        initialAllocation[zoneName] += passengersToAdd;
        remainingPassengers -= passengersToAdd;
      }
    }

    return initialAllocation;
  };

  const calculateBaggage = (event) => {
    event.preventDefault();

    if (!planeType) {
      alert('Please select a plane type.');
      return;
    }

    const plane = planeData[planeType];

    const paxCountNum = parseInt(passengerCount, 10);

    if (isNaN(paxCountNum) || paxCountNum < 0) {
      alert('Please enter a valid passenger count.');
      return;
    }

    if (paxCountNum > plane.maxPassengers) {
      alert(
        `The maximum number of passengers for ${planeType} is ${plane.maxPassengers}.`
      );
      return;
    }

    const totalBags = getTotalBags(paxCountNum);

    // Calculate bin allocations
    const { binC, binD } = plane.calculateBins(totalBags);

    // Calculate bag weights
    const fwdWeight = Math.round(
      binC * NORMAL_BAG_WEIGHT + binC * 0.1 * HEAVY_BAG_WEIGHT
    );
    const aftWeight = Math.round(
      binD * NORMAL_BAG_WEIGHT +
        binD * 0.1 * HEAVY_BAG_WEIGHT +
        parseFloat(freightWeight || 0)
    );

    // Calculate passenger weights
    const randomPercentage = Math.random() * 0.2; // 0 to 20%
    const childPaxCount = Math.round(paxCountNum * randomPercentage);
    const adultPaxCount = paxCountNum - childPaxCount;

    const totalPaxWeight = Math.round(
      adultPaxCount * AVG_ADULT_WEIGHT + childPaxCount * AVG_CHILD_WEIGHT
    );

    const totalBinWeights = fwdWeight + aftWeight;
    const totalPayload = Math.round(totalPaxWeight + totalBinWeights);
    const ZFW = plane.BEW + totalPayload + plane.PMDGZFWCorrection;

    // Calculate SimBrief results
    const calculateSimBriefResults = () => {
      const totalAdultPassengerWeight = adultPaxCount * AVG_ADULT_WEIGHT;
      const totalNormalBagWeight = (binC + binD) * NORMAL_BAG_WEIGHT;
      const totalBinWeightsExcludingHeavy = Math.round(
        totalBinWeights -
          binC * 0.1 * HEAVY_BAG_WEIGHT -
          binD * 0.1 * HEAVY_BAG_WEIGHT
      );
      const totalPayloadSimBrief =
        totalAdultPassengerWeight +
        totalNormalBagWeight +
        parseFloat(freightWeight || 0);

      setSimBriefResults({
        totalPax: adultPaxCount,
        totalPayload: totalPayloadSimBrief,
        totalBinWeightsExcludingHeavy: totalBinWeightsExcludingHeavy,
      });
    };

    // Passenger distribution for 737-MAX8
    if (planeType === '737-MAX8' && plane.passengerZones) {
      const distribution = distributePassengersToZones(
        paxCountNum,
        plane.passengerZones
      );
      setPassengerDistribution(distribution);
    } else {
      setPassengerDistribution(null);
    }

    // Simulate loading
    setResultsLoading(true);
    setButtonText('Estimating...');
    setTimeout(() => {
      setButtonText('Estimate Baggage');
      setResults({
        adultPaxCount: adultPaxCount,
        childPaxCount: childPaxCount,
        totalBags: totalBags,
        fwdWeight: fwdWeight,
        aftWeight: aftWeight,
        totalBinWeights: totalBinWeights,
        totalPayload: totalPayload,
        ZFW: ZFW,
      });
      calculateSimBriefResults();
      setResultsLoading(false);
    }, 2000);
  };

  // Progress bar effect
  useEffect(() => {
    if (resultsLoading) {
      setProgress(0);
      let timer;
      const startProgress = () => {
        timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress >= 100) {
              clearInterval(timer);
              return 100;
            }
            const increment =
              Math.random() > 0.3 ? Math.floor(Math.random() * 2) + 1 : 0;
            return Math.min(oldProgress + increment, 100);
          });
        }, 100);
      };
      startProgress();

      return () => {
        clearInterval(timer);
      };
    } else {
      setProgress(0);
    }
  }, [resultsLoading]);

  return (
    <div className="container">
      <h2>Payload Estimator</h2>
      <div className="form-container">
        <form onSubmit={calculateBaggage}>
          {/* Plane Type */}
          <label htmlFor="planeType">Plane Type</label>
          <select
            id="planeType"
            value={planeType}
            onChange={(e) => setPlaneType(e.target.value)}
            required
          >
            <option value="">Select a plane</option>
            <option value="737-700">PMDG 737-700</option>
            <option value="737-800">PMDG 737-800</option>
            <option value="777-300ER">PMDG 777-300ER</option>
            <option value="737-MAX8">IFLY 737-MAX8</option>
            <option value="A319">Fenix A319-100</option>
            <option value="A320">Fenix A320-200</option>
            <option value="A321">Fenix A321-200</option>
          </select>

          {/* Passenger Count */}
          <label htmlFor="passengerCount">Passenger Count</label>
          <input
            type="number"
            id="passengerCount"
            value={passengerCount}
            onChange={(e) => setPassengerCount(e.target.value)}
            placeholder="Enter number of passengers"
            min="0"
            max={maxPassengerCount || ''}
            disabled={!planeType}
            required
          />

          {/* Freight Weight */}
          <label htmlFor="freightWeight">
            Freight Weight (lbs) (optional input)
          </label>
          <input
            type="number"
            id="freightWeight"
            value={freightWeight}
            onChange={(e) => setFreightWeight(e.target.value)}
            placeholder="Enter freight weight"
            min="0"
            disabled={!planeType}
          />

          <button type="submit" className="btn" disabled={!planeType}>
            {buttonText}
          </button>
        </form>
      </div>

      {/* Results Display */}
      <div className="results-container">
        {/* Actual Estimation Results */}
        {resultsLoading ? (
          <div className="result-card">
            <div className="loader-container">
              <div className="loader"></div>
              <div className="progress-text">{progress}%</div>
            </div>
          </div>
        ) : results.totalPayload ? (
          <div className="result-card">
            <h3>Actual Estimation Results</h3>
            <ul>
              <li>Total Adult Passengers: {results.adultPaxCount}</li>
              <li>Total Child Passengers: {results.childPaxCount}</li>
              <li>Number of Bags: {results.totalBags}</li>
              <li>FWD Bins Weight: {results.fwdWeight} lbs</li>
              <li>AFT Bins Weight: {results.aftWeight} lbs</li>
              <li>Total Bin Weights: {results.totalBinWeights} lbs</li>
              <li>Total Payload: {results.totalPayload} lbs</li>
              <li>(PMDG ONLY) Zero Fuel Weight: {results.ZFW} lbs</li>
            </ul>
          </div>
        ) : null}

        {/* SimBrief Data Input */}
        {resultsLoading ? (
          <div className="result-card">
            <div className="loader-container">
              <div className="loader"></div>
              <div className="progress-text">{progress}%</div>
            </div>
          </div>
        ) : simBriefResults.totalPayload ? (
          <div className="result-card">
            <h3>SimBrief Data Input</h3>
            <p className="note">
              * This excludes child weights and heavy bags from the payload.
            </p>
            <ul>
              <li>
                Total Adult Passengers: {simBriefResults.totalPax}
                <FaQuestionCircle
                  data-tooltip-id="simTotalPaxTip"
                  data-tooltip-content={tooltipContent.adultPaxCount}
                  className="question-icon"
                />
                <Tooltip id="simTotalPaxTip" place="right" />
              </li>
              <li>
                Total Bin Weights: {simBriefResults.totalBinWeightsExcludingHeavy}{' '}
                lbs
              </li>
              <li>
                Total Payload (without child weights and heavy bags):{' '}
                {simBriefResults.totalPayload} lbs
                <FaQuestionCircle
                  data-tooltip-id="simTotalPayloadTip"
                  data-tooltip-content={tooltipContent.totalPayload}
                  className="question-icon"
                />
                <Tooltip id="simTotalPayloadTip" place="right" />
              </li>
              <li>
                (PMDG ONLY) Zero Fuel Weight: {results.ZFW} lbs
              </li>
            </ul>
          </div>
        ) : null}

        {/* Passenger Distribution for 737-MAX8 */}
{planeType === '737-MAX8' && (
  resultsLoading ? (
    <div className="result-card">
      <div className="loader-container">
        <div className="loader"></div>
        <div className="progress-text">{progress}%</div>
      </div>
    </div>
  ) : passengerDistribution ? (
    <div className="result-card">
      <h3>Passenger Distribution into Zones</h3>
      <table className="zone-table">
        <thead>
          <tr>
            <th>Zone</th>
            <th>Capacity</th>
            <th>Distributed Passengers</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(planeData[planeType].passengerZones).map(
            ([zoneName, zoneData]) => (
              <tr key={zoneName}>
                <td>{zoneName}</td>
                <td>{zoneData.capacity}</td>
                <td>{passengerDistribution[zoneName] || 0}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <p>
        <strong>Total Assigned Passengers:</strong>{' '}
        {Object.values(passengerDistribution).reduce(
          (sum, val) => sum + val,
          0
        )}
      </p>
    </div>
  ) : null
)}

      </div>
    </div>
  );
}

export default BaggageEstimator;
