// Changelog.js
import React from 'react';
import './Changelog.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import changelogData from './changelogData';

function Changelog() {
  const [activeIndex, setActiveIndex] = React.useState(null);

  // Total number of changelog entries
  const totalEntries = changelogData.length;

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container">
      <h2>Changelog</h2>
      <div className="changelog">
        {changelogData.map((entry, index) => (
          <div key={index} className="changelog-entry">
            <div
              className={`changelog-header ${
                activeIndex === index ? 'active' : ''
              }`}
              onClick={() => toggleAccordion(index)}
              role="button"
              tabIndex="0"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleAccordion(index);
                }
              }}
              aria-expanded={activeIndex === index}
              aria-controls={`changelog-content-${index}`}
            >
              <div className="changelog-header-left">
                <h3>{entry.version}</h3>
                {index === 0 && (
                  <span className="new-label" aria-label="New Update">
                    NEW!
                  </span>
                )}
              </div>
              <p>{entry.date}</p>
              <div className="changelog-icon">
                {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            {activeIndex === index && (
              <div
                id={`changelog-content-${index}`}
                className="changelog-content"
                role="region"
                aria-labelledby={`changelog-header-${index}`}
              >
                <ul>
                  {entry.changes.map((change, idx) => (
                    <li key={idx}>{change}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
      {/* Total entries display */}
      <div className="total-entries">
        Total Updates: {totalEntries}
      </div>
    </div>
  );
}

export default Changelog;
