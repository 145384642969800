// SubmitRequest.js
import React, { useState } from 'react';
import './SubmitRequest.css';
import axios from 'axios';

function SubmitRequest() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Replace with your backend endpoint if necessary
    const url = `${process.env.REACT_APP_BACKEND_URL}/submit_request.php`;

    try {
      const response = await axios.post(url, { title, description });
      setMessage('Your request has been submitted successfully!');
      // Clear the form
      setTitle('');
      setDescription('');
    } catch (err) {
      console.error('Submission error:', err);
      setMessage('There was an error submitting your request. Please try again.');
    }
  };

  return (
    <div className="submit-request-container">
      <h1>Submit a Request</h1>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit} className="submit-request-form">
        <label htmlFor="title">Request Title (Example: Boeing 747-400 Request for addition):</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <label htmlFor="description">Request Description (Only Add Aircraft, Open Empty Weights, Max Bin Weights, and reason why): </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>

        <button type="submit" className="btn">
          Submit Request
        </button>
      </form>
    </div>
  );
}

export default SubmitRequest;
