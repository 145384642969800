// Disclaimer.js
import React from 'react';
import changelogData from './changelogData'; // Adjust the path as needed

function Disclaimer() {
  // Get the latest version and date from the changelog data
  const latestVersion = changelogData.length > 0 ? changelogData[0].version : 'v1.0.0';
  const latestDate = changelogData.length > 0 ? changelogData[0].date : '';

  return (
    <div>
      <p className="mt-8 text-sm text-gray-500">
        Note: The estimations provided by this tool may differ from other platforms like SimBrief. This tool aims to provide a more accurate estimation based on real-world data.
      </p>
      <p className="text-sm text-gray-500">
        © Simpayloads.com {latestVersion} {latestDate} | Owned by EnterMax. 
      </p>
    </div>
  );
}

export default Disclaimer;
