// ProfilePage.js
import React, { useState, useEffect, useContext } from 'react';
import ProfileDisplay from './ProfileDisplay';
import ProfileEditForm from './ProfileEditForm';
import { AuthContext } from './AuthContext';

function ProfilePage() {
  const { setUser } = useContext(AuthContext);
  const [profileData, setProfileData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    // Fetch profile data when component mounts
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUserProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/getUserProfile.php`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProfileData(data);
      } else {
        const errorData = await response.json();
        console.error('Error fetching profile data:', errorData.message);
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  return (
    <div className="profile-page">
      <h1>Profile</h1>
      {editMode ? (
        <ProfileEditForm
          profileData={profileData}
          setProfileData={setProfileData}
          setEditMode={setEditMode}
          setUser={setUser}
        />
      ) : (
        <ProfileDisplay
          profileData={profileData}
          setEditMode={setEditMode}
        />
      )}
    </div>
  );
}

export default ProfilePage;
