// LoginModal.js
import React, { useState, useContext } from 'react';
import './LoginModal.css';
import axios from 'axios';
import { AuthContext } from './AuthContext'; // Import AuthContext

const LoginModal = ({ onClose }) => {
  const { setIsAuthenticated, setUser } = useContext(AuthContext); // Get setters from context

  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSwitch = () => {
    setIsLogin(!isLogin);
    setError('');
    // Clear all fields when switching
    setUsername('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!username || !password || (!isLogin && !email)) {
      setError('Please fill in all required fields.');
      return;
    }

    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    const url = isLogin
      ? `${process.env.REACT_APP_BACKEND_URL}/login.php`
      : `${process.env.REACT_APP_BACKEND_URL}/register.php`;

    const payload = isLogin
      ? { username, password }
      : { username, email, password };

    try {
      const response = await axios.post(url, payload);
      if (isLogin) {
        // Save token to localStorage or context
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.username);
        localStorage.setItem('email', response.data.email);

        // Update AuthContext
        setIsAuthenticated(true);
        setUser({
          username: response.data.username,
          email: response.data.email,
          token: response.data.token,
        });

        onClose(); // Close modal after successful login
      } else {
        alert('Registration successful! You can now log in.');
        setIsLogin(true);
      }
    } catch (err) {
      console.error('Authentication error:', err);
      setError(err.response?.data?.message || 'Server error');
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h2 className="modal-title">Welcome to SimPayloads</h2>
        <h2 className="modal-title">{isLogin ? 'Login' : 'Register'}</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          {!isLogin && (
            <>
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </>
          )}

          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {!isLogin && (
            <>
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </>
          )}

          <button type="submit" className="btn">
            {isLogin ? 'Login' : 'Register'}
          </button>
        </form>
        <p className="switch-text">
          {isLogin ? "Don't have an account?" : 'Already have an account?'}
          <button onClick={handleSwitch} className="btn-link">
            {isLogin ? 'Register' : 'Login'}
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginModal;
