// AccountPage.js
import React, { useContext } from 'react';
import { AuthContext } from './AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar'; // New Sidebar component
import './AccountPage.css';

function AccountPage() {
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="account-page">
      <Sidebar />
      <div className="account-content">
        <Outlet /> {/* Renders the matched child route */}
      </div>
    </div>
  );
}

export default AccountPage;
