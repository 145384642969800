// ProfileDisplay.js
import React from 'react';

function ProfileDisplay({ profileData, setEditMode }) {
  return (
    <div className="profile-display">
      <p><strong>Username:</strong> {profileData?.username}</p>
      <p><strong>Email:</strong> {profileData?.email}</p>
      <button onClick={() => setEditMode(true)}>Edit Profile</button>
    </div>
  );
}

export default ProfileDisplay;
