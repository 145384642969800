// ContactUs.js
import React from 'react';
import './ContactUs.css';

function ContactUs() {
  const handleJoinDiscord = () => {
    window.open('https://discord.gg/hXw4VPG5v3', '_blank');
  };

  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <p>If you have any questions or need support, please reach out to us at:</p>
      <p>Email: <a href="mailto:contactus@simpayloads.com">contactus@simpayloads.com</a></p>
      <button className="join-discord-button" onClick={handleJoinDiscord}>
        Join Discord
      </button>
      {/* Optional: Add a contact form */}
    </div>
  );
}

export default ContactUs;
